import Image from "next/image";
import Link from "next/link";
import styled from "styled-components";
import { DOWNLOAD_URL_ANDROID, DOWNLOAD_URL_IOS } from "../../utilities/constants/urls";
import { MEDIA_QUERY_MD } from "../../utilities/constants/mediaQueries";
import { ReactNode, useEffect } from "react";
import { isMobile, isIOS, isAndroid } from "react-device-detect";
import { useRouter } from "next/router";

const Container = styled.div`
  position: fixed;
  display: flex;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow: hidden;

  @media (min-width: ${MEDIA_QUERY_MD}) {
    height: 100dvh;
    position: static;
  }
`;

const Main = styled.main`
  flex-direction: column;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const MobileScreen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default function AuthLayout({ noRedirect, children }: { noRedirect?: boolean; children: ReactNode }) {
  const router = useRouter();

  useEffect(() => {
    if (!isMobile || noRedirect) return;

    if (isIOS) {
      router.push(DOWNLOAD_URL_IOS);
    } else if (isAndroid) {
      router.push(DOWNLOAD_URL_ANDROID);
    } else {
      router.push("/?qr=1");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Main>
        {isMobile && !noRedirect && (
          <MobileScreen>
            <Image src="/img/app_logo.png" alt="Bunches" width="120" height="120" />
            {isIOS && (
              <>
                <p>
                  <a href={DOWNLOAD_URL_IOS}>Download Bunches!</a>
                </p>
              </>
            )}
            {isAndroid && (
              <>
                <p>
                  <Link href={DOWNLOAD_URL_ANDROID}>Download Bunches!</Link>
                </p>
              </>
            )}
          </MobileScreen>
        )}
        {(!isMobile || noRedirect) && children}
      </Main>
    </Container>
  );
}
