import { ButtonHTMLAttributes, MouseEventHandler, ReactNode } from "react";
import styled from "styled-components";
import { MEDIA_QUERY_MD } from "../../../utilities/constants/mediaQueries";

const StyledButton = styled.button<{
  primary: boolean;
  secondary: boolean;
  tertiary: boolean;
  small: boolean;
  xsmall: boolean;
  danger: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 600;
  background: ${({ primary }) => primary && `var(--color-primary)`};
  color: var(--color-white);
  border-radius: 9999px;
  padding: 0.5rem 2rem;
  cursor: pointer;
  border: 2px solid transparent;
  outline: 2px solid transparent;
  transition: all var(--animation-timing-quick) var(--timing-easeOutQuart);

  &:disabled {
    background: var(--color-gray-4);
    cursor: default;
  }

  @media (hover: hover) {
    &:hover:not(:disabled),
    &:focus {
      outline-color: var(--color-primary);
      border-color: var(--color-background);
    }
  }

  ${({ small }) =>
    small &&
    `
    padding: 0.25rem 1rem;
    font-weight: 400;
    font-size: 0.75rem;

    @media (min-width: ${MEDIA_QUERY_MD}) {
      font-size: 0.875rem;
      padding: 0.4rem 1.9rem;
    }
  `}

  ${({ xsmall }) =>
    xsmall &&
    `
    padding: 0.25rem 1rem;
    font-weight: 400;
    font-size: 0.675rem;

    @media (min-width: ${MEDIA_QUERY_MD}) {
      font-size: 0.75rem;
      padding: 0.25rem 1.25rem;
    }
  `}

  ${({ secondary }) =>
    secondary &&
    `
    background: transparent;
    color: var(--color-primary);

    &:hover {
      text-decoration: underline;
    }
  `}

${({ tertiary }) =>
    tertiary &&
    `
    background: transparent;
    color: var(--color-gray-3);
    outline: 2px solid transparent;
    border: 2px solid var(--color-gray-3);

    &:focus {
      outline-color: transparent;
      border-color: var(--color-primary);
    }
  `}

  ${({ danger }) =>
    danger &&
    `
      color: var(--color-error);

      &:focus {
        outline-color: transparent;
        border-color: var(--color-error);
      }

      &:hover {
        border-color: var(--color-error) !important;
        outline-color: transparent !important;
        text-decoration: underline;
      }
  `}
`;

export default function Button({
  primary = true,
  secondary = false,
  tertiary = false,
  danger = false,
  small = false,
  xsmall = false,
  className,
  ...props
}: {
  primary?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  danger?: boolean;
  small?: boolean;
  xsmall?: boolean;
  children: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  tabIndex?: number;
  disabled?: boolean;
  className?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <StyledButton
      className={className}
      primary={primary && !secondary && !tertiary && !danger}
      secondary={secondary}
      tertiary={tertiary}
      danger={danger}
      small={small}
      xsmall={xsmall}
      {...props}
    />
  );
}
