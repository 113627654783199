import gql from "graphql-tag";
import { CheckInUserMutation, CheckInUserMutationVariables } from "../types";
import { TypedDocumentNode } from "@apollo/client";

export const CHECK_IN_USER: TypedDocumentNode<CheckInUserMutation, CheckInUserMutationVariables> = gql`
  mutation checkInUser($userId: ID!, $deviceId: String!, $timezone: String!, $type: String!, $isIos: Boolean!) {
    checkinUser(userId: $userId, deviceId: $deviceId, type: $type, isIos: $isIos, timezone: $timezone) {
      username
    }
  }
`;
