import { ReactNode } from "react";
import styled from "styled-components";

const StyledHeading = styled.h1<{ fontSize?: string; rift: boolean }>`
  color: var(--color-gray-1);
  font-weight: 600;
  line-height: 1.4;
  margin: 0;
  font-size: ${({ fontSize }) => fontSize || "1rem"};
  ${({ rift }) => rift && "font-family: var(--font-family-headline);"}
`;

export default function Heading({
  children,
  fontSize,
  rift = false,
  ...props
}: {
  children: ReactNode;
  fontSize?: string;
  rift?: boolean;
}) {
  return (
    <StyledHeading fontSize={fontSize} rift={rift} {...props}>
      {children}
    </StyledHeading>
  );
}
