import { TypedDocumentNode } from "@apollo/client";
import gql from "graphql-tag";
import { USER_DETAILS } from "../fragments";
import {
  PrivyLoginMutation,
  PrivyLoginMutationVariables,
  SendSmsCodeQuery,
  SendSmsCodeQueryVariables,
  SmsLoginMutation,
  SmsLoginMutationVariables,
  VerifySmsCodeQuery,
  VerifySmsCodeQueryVariables,
} from "../types";

export const PRIVY_LOGIN: TypedDocumentNode<PrivyLoginMutation, PrivyLoginMutationVariables> = gql`
  ${USER_DETAILS}
  mutation privyLogin($user: PrivyUser!) {
    privyLogin(user: $user) {
      ...UserDetails
    }
  }
`;

export const SEND_SMS_CODE: TypedDocumentNode<SendSmsCodeQuery, SendSmsCodeQueryVariables> = gql`
  query sendSMSCode($phone: String!) {
    sendSmsCode(phone: $phone)
  }
`;

export const VERIFY_SMS_CODE: TypedDocumentNode<VerifySmsCodeQuery, VerifySmsCodeQueryVariables> = gql`
  query verifySMSCode($phone: String!, $code: String!) {
    verifySmsCode(phone: $phone, code: $code)
  }
`;

export const SMS_LOGIN: TypedDocumentNode<SmsLoginMutation, SmsLoginMutationVariables> = gql`
  ${USER_DETAILS}
  mutation smsLogin($phone: String!, $code: String!, $source: String) {
    smsLogin(phone: $phone, code: $code, source: $source) {
      ...UserDetails
    }
  }
`;
